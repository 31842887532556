import { InputHTMLAttributes } from 'react';
import { setStore, useStore } from 'store';

type Props = InputHTMLAttributes<HTMLInputElement> & {
	field: string;
	label: string;
	htmlName: string;
};

export function Input(props: Props)
{
	const [order, appStatus] = useStore(s => [s.order, s.appStatus]);
	const { field, label, type, htmlName, ...rest } = props;
	const { customer } = order;

	return (
		<label className='row'>
			<div
				className='col-4 col-sm-3 pr-0'
				style={{ alignSelf: 'center' }}
			>
				{label}
			</div>
			<div className='col-8 col-sm-9'>
				<input
					{...rest}
					className='form-control'
					style={{ fontSize: '0.85rem' }}
					type={type || 'text'}
					spellCheck={false}
					autoFocus={false}
					required
					name={htmlName}
					placeholder={label}
					autoComplete={htmlName}
					value={customer?.[field]}
					onChange={e =>
					{
						order.customer[field] = e.target.value;
						appStatus.formValid = Boolean(e.target?.form?.checkValidity());
						setStore({ order, appStatus });
					}}
				/>
			</div>
		</label>
	);
}
