// export const colorScheme = {
//     mainColor: 'ff0000',
//     mainColorDark: 'dd0000',
//     mainColorDarker: '880000',
//     mainColorDarkest: '550000',
// }

export const colorScheme = {
	mainColor: '28a745',
	mainColorDark: '218838',
	mainColorDarker: '1e7e34',
	mainColorDarkest: '1c7430',
};
