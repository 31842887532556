import { useStore } from 'store';
import { NavLink, Redirect } from 'react-router-dom';
import { useStated } from 'hooks/Hooks';
import styled from 'styled-components';

const HeaderContainer = styled.div`
	position: relative;
	/* position: sticky; */
	/* top: 0; */
	padding: 20px 10px 0 10px;
	z-index: 2;
	background: #fff;
`;

const HeaderDiv = styled.div`
	width: 100%;
	/* box-shadow: #00000020 0px 10px 10px -10px; */
	box-shadow: #00000030 0px 10px 15px -10px;
	/* border-bottom: 1px solid #eee; */
	padding-bottom: 10px;
	z-index: 1;
	cursor: pointer;
`;

export function NavItem(props: { title: string; badge: string; to: string; exact?: boolean; disabled?: boolean; onClick?: any; })
{
	const [state, set] = useStated({
		navigate: false,
	});

	const { to, exact, disabled, title, badge } = props;
	const { navigate } = state;

	if (navigate)
	{
		set({ navigate: false });
		return <Redirect to={to} />;
	}

	return (
		<li className='nav-item'>
			<NavLink
				to={to}
				exact={exact}
				className={'nav-link pointer' + (disabled ? ' disabled' : '')}
				onDragStart={e => e.preventDefault()}
				role='tab'
			>
				{title}
				<span className='nro'>{badge}</span>
			</NavLink>
		</li>
	);
}

export function Header()
{
	const [order, appStatus] = useStore(s => [s.order, s.appStatus]);
	const { start, end, office, resource } = order;
	const { formValid, agreed } = appStatus || {};
	const { href, pathname } = window.location;

	return (
		<HeaderContainer>
			<div className='row'>
				<HeaderDiv className='col-12'>
					<ul
						className='nav nav-tabs'
						role='tablist'
					>
						{(href.match(/maksu\/\w+/) && (
							<NavItem
								badge={'4'}
								title='Maksu'
								to=''
								disabled={true}
							/>
						)) ||
							(href.match('/valmis') && (
								<NavItem
									badge={'OK'}
									title='Varaus tehty'
									to=''
									disabled={true}
								/>
							)) || (
								<>
									<NavItem
										badge={'1'}
										title='Ajanvaraus'
										to={['', '/', '/valinnat', '/varaus'].includes(pathname) ? pathname : '/varaus'}
									/>
									<NavItem
										badge={'2'}
										title='Yhteystiedot'
										to='yhteystiedot'
										disabled={!office || !resource || !start || !end}
									/>
									<NavItem
										badge={'3'}
										title='Vahvistus'
										to='vahvistus'
										disabled={!office || !resource || !start || !end || !formValid || !agreed}
									/>
									{href.match('/maksu') && (
										<NavItem
											badge={'4'}
											title='Maksu'
											to='maksu'
											disabled={!office || !resource || !start || !end || !formValid || !agreed}
										/>
									)}
								</>
							)}
					</ul>
				</HeaderDiv>
			</div>
		</HeaderContainer>
	);
}
