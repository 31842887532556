import _ from 'lodash';
import moment from 'moment';
import styled, { StyledComponent } from 'styled-components';
import { dateRoundUp } from 'App';
import { useMount, useStated } from 'hooks/Hooks';
import { setStore, useStore } from 'store';
import { Post } from 'utils/Api';
import { SqlDate } from 'utils/Dates';
import { Loader } from './Loader';
// import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
// import { BackendURL } from 'utils/Api';

const createContainer = (customCss: string) =>
{
	return styled.div`
		background: #fff;
		position: relative;
		overflow: auto;
		width: 100%;
		height: 100%;

		> div {
			padding: 1rem 1.5rem;
		}

		h2 {
			font-weight: 600;
			line-height: 1.1;
			margin: 0 0 2.1428571429rem;
		}

		h2 {
			font-size: 2.2857142857rem;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}

		p {
			margin: 0 0 2.1428571429rem;
		}

		ul {
			margin: 0 0 2.1428571429rem;
		}

		fieldset {
			border: 1px solid #efefef;
			border-radius: 0.5rem;
			padding: 1.4285714286rem 1.4285714286rem 0;
		}

		legend {
			font-size: 1rem;
			font-weight: 600;
			margin: 0 -0.3571428571rem;
			padding: 0 0.3571428571rem;
		}

		.input--hidden {
			display: none;
		}

		.button {
			background: #ee4035;
			border: 0;
			border-radius: 0.3571428571rem;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			font-size: 1.1428571429rem;
			font-weight: 700;
			height: 3.4285714286rem;
			min-width: 6.4285714286rem;
			overflow: hidden;
			padding: 0.9285714286rem 1.7857142857rem;
			text-align: center;
			vertical-align: top;
			white-space: nowrap;
		}

		.button:hover {
			background: #dd1f13;
			text-decoration: none;
		}

		.button--small {
			font-size: 0.8571428571rem;
			height: unset;
			line-height: 0.8571428571rem;
			padding: 0.3571428571rem 0.7142857143rem;
			vertical-align: baseline;
		}

		.button--disabled {
			opacity: 0.7;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		.button--disabled:hover {
			cursor: not-allowed;
		}

		.button--gray {
			background: #eee;
			color: #343434;
			font-weight: 400;
		}

		.button--gray:hover {
			background: #e1e1e1;
		}

		.calendar-legend {
			align-items: center;
			display: flex;
			flex-wrap: nowrap;
			margin: 0 0 2.1428571429rem;
			padding-left: 20px;
		}

		.calendar-legend__item {
			align-items: center;
			display: flex;
			margin-right: 2.1428571429rem;
			white-space: nowrap;
		}

		.calendar-legend__item:last-of-type {
			margin-right: 0;
		}

		.calendar-legend__icon {
			border-radius: 0.2142857143rem;
			height: 1.0714285714rem;
			margin: auto 0.3571428571rem auto 0;
			width: 1.0714285714rem;
		}

		.calendar-legend__icon--free {
			background: #eee;
			border: 1px solid #ddd;
		}

		.calendar-legend__icon--unavailable {
			background: #ff8680;
		}

		.calendar-legend__icon--chosen {
			background: #93d08e;
		}

		.calendar-week {
			display: flex;
			align-self: center;
			font-size: 1.2857142857rem;
			justify-content: space-between;
		}

		.calendar-week__current,
		.calendar-week__move {
			vertical-align: top;
		}

		.calendar-week__move {
			background: #eee;
			border-radius: 4px;
			font-size: 0.7857142857rem;
			line-height: 2;
			margin: auto 20px;
			padding: 0 15px;
		}

		.calendar-week__move:hover {
			background: #e1e1e1;
			text-decoration: none;
		}

		.calendar-week__current {
			padding: 1rem 1.5rem;
		}

		.calendar-product {
			background: #fff;
			flex: 0 1 48%;
			margin: 0 0 2rem;
		}

		.calendar-product .list {
			flex: 1;
		}

		.calendar-product__unit {
			background-color: #fff;
			border: 1px solid #343434;
			box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.35);
			color: #343434;
			font-size: 14px;
			margin: 0 0.7142857143rem 0.7142857143rem 0;
			padding: 5px 0 6px 15px;
			position: relative;
		}

		.calendar-product__unit:hover {
			background: transparent;
		}

		.calendar-product__unit:before {
			background-color: #384cff;
			border-bottom-left-radius: 3px;
			border-top-left-radius: 3px;
			bottom: 0;
			color: #fff;
			content: 'FIN';
			display: block;
			font-size: 8px;
			font-weight: 400;
			left: 0;
			padding-top: 10px;
			position: absolute;
			top: 0;
			width: 15px;
		}

		.calendar-product__unit--selected {
			box-shadow: 0 0 0 1px #fff, 0 2px 3px -2px rgba(0, 0, 0, 0.75), 0 0 0 3px #93d08e;
		}

		.calendar-product__toggle {
			float: right;
		}

		.calendar-product__collapse {
			line-height: 1rem;
		}

		.calendar-product__collapse span {
			margin-right: 10px;
		}

		.calendar-days {
			border-top: 1px solid #eee;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
		}

		.calendar-day {
			border-left: 1px solid #eee;
			flex: 1;
			font-size: 1.2rem;
			white-space: nowrap;
		}

		.calendar-day:first-child {
			border-left: 0;
		}

		.calendar-day__title {
			border-bottom: 1px solid #eee;
			line-height: 3.7;
			text-align: center;
			text-transform: capitalize;
		}

		.calendar-day__slots {
			padding: 0.75rem 0.35rem;
		}

		.calendar-day__truncated-title {
			border-bottom: 1px solid #eee;
			display: none;
			line-height: 3.7;
			text-align: center;
			text-transform: capitalize;
		}

		.calendar-group {
			line-height: 2;
			text-align: center;
		}

		.calendar-group + .calendar-group {
			margin-top: 10px;
		}

		.calendar-group__item {
			position: relative;
		}

		.calendar-group__item:first-child {
			border-top-left-radius: 0.3571428571rem;
			border-top-right-radius: 0.3571428571rem;
		}

		.calendar-group__item:last-child {
			border-bottom-left-radius: 0.3571428571rem;
			border-bottom-right-radius: 0.3571428571rem;
		}

		.calendar-group__button {
			background: none;
			border: 0;
			color: inherit;
			cursor: pointer;
			display: block;
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			padding: 0;
			width: 100%;
			font-size: 1.2rem;
		}

		.content__column {
			padding: 1.5rem 1rem;
		}

		.content__column h2 ~ p {
			color: #888;
		}

		.content__column h2 ~ p strong {
			color: #000;
			font-size: 1.1em;
			font-weight: 400;
		}

		.content__column--wide {
			flex: 1;
		}

		.list {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		.list--inline {
			padding: 0 0 10px;
		}

		.list--inline li {
			display: inline-block;
		}

		.calendar-group {
			.free {
				background: #f8f8f8;
				border: 1px solid #0002;
				color: #333e;
			}
			.reserved {
				border: 1px solid #0002;
				background: #f88e;
				color: #a54e;
			}
		}

		@media (max-width: 1400px) {
			h2 {
				margin: 0 0 1.4285714286rem;
				font-size: 2rem;
			}
			.calendar-legend {
				display: block;
				flex-wrap: unset;
				margin: 0 0 1.0714285714rem;
				padding-left: 0;
			}
			.calendar-legend__item {
				white-space: unset;
			}
			.calendar-legend__item--buttons {
				display: block;
				margin-top: 10px;
			}
			.calendar-legend__item--truncate {
				display: none;
			}
			.calendar-product {
				flex: 0 1 100%;
				margin: 0 0 1rem;
				padding-left: 8px;
				padding-right: 6px;
				width: 100%;
			}
			.calendar-week__current {
				margin: 0;
			}
			.calendar-week__move {
				line-height: 3;
				margin: auto 10px;
				padding: 0 8px;
			}

			.calendar-day__truncated-title {
				display: block;
			}
			.calendar-day__title {
				display: none;
			}
			.calendar-days {
				min-width: 500px;
			}
			.calendar-product__unit {
				font-size: 12px;
				margin-right: 4px;
				min-width: 80px;
			}
		}

		${ customCss }
	`;
};

export const InfoDisplay = () =>
{
	const order = useStore(s => s.order);
	const [state, set] = useStated({
		customCss: undefined as void | string,
		Container: undefined as void | StyledComponent<'div', any, {}, never>,
	});

	const { start, office, resource, schedules, offices, resources } = order;
	let timeStep = 60;

	useMount(async () =>
	{
		if (state.Container !== undefined) return;
		if (state.customCss !== undefined) return;
		try
		{
			const customCss = await (await fetch('https://oitis.fi/infonaytto/custom.css')).text();
			const Container = createContainer(customCss);
			set({ customCss, Container });
		} catch (e)
		{
			const Container = createContainer('');
			set({ customCss: '', Container });
		}
	});

	const getSchedules = async () =>
	{
		order.schedules = await Post({
			path: 'schedule',
			body: {
				ending: '>=' + SqlDate(moment().subtract(1, 'd')),
				start: '<=' + SqlDate(moment().add(8, 'd')),
				resource: resource?.id,
			},
			header: { limit: 1000 },
		});
		setStore({ order });
	};

	useMount(() =>
	{
		console.log({ office, offices });

		if (!office?.id)
		{
			order.office = offices?.data?.[0];
			setStore({ order });
		}

		if (!resource?.id)
		{
			order.resource = resources?.data?.[0];
			setStore({ order });
		}

		if (office?.id && resource?.id)
		{
			getSchedules();
		}
	}, [office?.id, resource?.id]);

	const getRange = (day: moment.Moment) =>
	{
		const nowRounded = dateRoundUp(moment(day), timeStep).startOf('m');
		let rangeStart = nowRounded.clone();
		let rangeEnd = nowRounded.clone().endOf('d');

		_.map(office?.openings, v =>
		{
			if (v.weekday !== moment(rangeStart).weekday()) return;

			const opentime = moment(v?.opentime, [moment.ISO_8601, 'HH:mm']);
			const closetime = moment(v?.closetime, [moment.ISO_8601, 'HH:mm']);

			rangeStart.hour(opentime.hour()).minute(opentime.minute());
			rangeEnd.hour(closetime.hour()).minute(closetime.minute());
		});

		_.map(office?.holidays, v =>
		{
			if (!day.isBetween(moment(v.opendate), moment(v.closedate), undefined, '[]')) return;

			const opentime = moment(v.opentime, [moment.ISO_8601, 'HH:mm']);
			const closetime = moment(v.closetime, [moment.ISO_8601, 'HH:mm']);

			rangeStart.hour(opentime.hour()).minute(opentime.minute());
			rangeEnd.hour(closetime.hour()).minute(closetime.minute());
		});

		if (office?.return_whenever === true && start)
		{
			if (rangeStart.isAfter(start)) rangeStart.hour(0).minute(0);
			rangeEnd.hour(23).minute(59);
		}

		rangeStart = dateRoundUp(moment(_.max([Date.now(), +rangeStart])), timeStep).startOf('m');
		rangeEnd = dateRoundUp(moment(_.max([Date.now(), +rangeEnd])), timeStep).startOf('m');
		const dateRange = Math.round(rangeEnd.diff(rangeStart, 'm') / timeStep);

		return { rangeStart, rangeEnd, dateRange };
	};

	// useMount(() =>
	// {
	// 	const EventSource = NativeEventSource || EventSourcePolyfill;
	// 	const eventSource = new EventSource(`${ BackendURL }/poll`, {
	// 		withCredentials: true,
	// 	});

	// 	eventSource.onopen = e => console.log('EventSource open', e);
	// 	eventSource.onerror = e => console.log('EventSource error', e);
	// 	eventSource.onmessage = e =>
	// 	{
	// 		try
	// 		{
	// 			// console.log('EventSource message', e);
	// 			const events = JSON.parse(e?.data);
	// 			// _.map(events, path => refetch(path));
	// 		}
	// 		catch (e)
	// 		{
	// 			console.error('EventSource error:', e);
	// 		}
	// 	};

	// 	return () => eventSource.close();
	// });

	useMount(() =>
	{
		const timer = setInterval(() =>
		{
			getSchedules();
		}, 1000);

		return () =>
		{
			clearInterval(timer);
		};
	});

	if (state.Container === undefined)
	{
		return <Loader size={42} />;
	}

	const { Container } = state;

	return (
		<Container>
			<div
				className='calendar-week'
				style={{
					alignItems: 'center',
					width: '100%',
					padding: '0 1rem!important',
					margin: '0!important',
				}}
			>
				<h2 style={{ margin: 0 }}>Saunavuorot</h2>
				<span className='calendar-week__current'>
					<>Viikko </>
					{moment().week()}
					{moment().weekday() !== 0 && (
						<>
							<> - </>
							{moment().add(1, 'week').week()}
						</>
					)}
				</span>
				<div
					className='calendar-legend'
					style={{ margin: 0, fontSize: '1.1rem' }}
				>
					<span className='calendar-legend__item'>
						<span className='calendar-legend__icon calendar-legend__icon--free' />
						<span>
							Vapaa <span className='calendar-legend__item--truncate'>aika</span>
						</span>
					</span>
					<span className='calendar-legend__item'>
						<span className='calendar-legend__icon calendar-legend__icon--unavailable' />
						<span>Varattu</span>
					</span>
				</div>
			</div>
			<div className='calendar-days-wrapper'>
				<div className='calendar-days'>
					{_.map(_.range(7), (_v, k) =>
					{
						const d = moment().startOf('d').add(k, 'day').toDate();

						return (
							<div
								key={k}
								className='calendar-day'
							>
								<div className='calendar-day__truncated-title'>{moment(d).format('ddd D.M')}</div>
								<div className='calendar-day__title'>{moment(d).format('dddd D.M')}</div>
								<div className='calendar-day__slots'>
									{(() =>
									{
										const { rangeStart, dateRange } = getRange(moment(d));

										if (!dateRange)
										{
											return (
												<small style={{ display: 'flex', whiteSpace: 'normal', textAlign: 'center', width: '100%' }}>
													Ei vapaita aikoja enää tänään
												</small>
											);
										}

										return _.map(_.range(dateRange), (v, k) =>
										{
											const d = moment(rangeStart).add(v * timeStep, 'minutes');
											const reserved = _.find(
												schedules?.data,
												schedule =>
													schedule?.resource?.id === resource?.id &&
													(moment(d).isBetween(moment(schedule?.start), moment(schedule?.ending), undefined, '[)') ||
														moment(d).isBetween(moment(schedule?.start), moment(schedule?.ending), undefined, '[)')),
											);

											return (
												<div
													key={k}
													className='calendar-group'
												>
													<div className={'calendar-group__item ' + (reserved ? 'reserved' : 'free')}>
														<button
															type='button'
															className='calendar-group__button'
														>
															{d.format('HH:mm')}
														</button>
													</div>
												</div>
											);
										});
									})()}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</Container>
	);
};
