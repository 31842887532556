import styled from 'styled-components';

export const HeaderItem = styled.div`
	/* position: absolute; */
	position: relative;
	display: flex;
	min-width: 100%;
	min-height: 120px;
	align-items: center;
	justify-content: center;
	/* padding: 8px; */

	> div:first-child {
		min-width: 100%;
		/* border-radius: 10px; */
		overflow: hidden;

		flex: 1;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		filter: brightness(0.8) contrast(0.8);

		align-items: center;
		justify-content: center;
	}

	> div:last-child {
		min-width: 100%;
		color: #fff;
		position: absolute;
	}

	> div > div:first-child {
		font-size: 16px;
		text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 4px #000;
		padding: 2px 10px;
		min-width: 100%;
		flex: 1;
	}
`;
