import _ from 'lodash';
import { useStore } from 'store';
import { Picture } from './Picture';
import { SelectBox } from './SelectBoxes';

export function Listing()
{
	const order = useStore(s => s.order);
	const { resources } = order;

	return (
		<div>
			{_.map(resources?.data, (v, k) => (
				<SelectBox
					key={k}
					className='col-6 col-md-4 col-lg-2'
					onClick={() =>
					{
						// onChange?.(v);
					}}
				>
					<div>
						<Picture
							src={v?.picture}
							alt={v?.name}
						/>
						<div>
							<div>{v?.name}</div>
						</div>
					</div>
				</SelectBox>
			))}
		</div>
	);
}
