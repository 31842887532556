import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useMount, useStated } from 'hooks/Hooks';
import { getStore, setStore, useStore } from 'store';
import { Post } from './utils/Api';
import { BrowserRouter, Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { TabCustomer } from './components/Tabs/TabCustomer';
import { TabConfirm } from './components/Tabs/TabConfirm';
import { TabPayment } from './components/Tabs/TabPayment';
import { TabReady } from './components/Tabs/TabReady';
import { Listing } from 'components/Listing';
import { Header } from 'components/Header';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TabResource } from 'components/Tabs/TabResource';
import { TabOffice } from 'components/Tabs/TabOffice';
import { TabSchedule } from 'components/Tabs/TabSchedule';
import { TabParent } from 'components/Tabs/Blocks/TabParent';
import { colorScheme } from 'utils/ColorScheme';
import { Loader } from 'components/Loader';
import { InfoDisplay } from 'components/InfoDisplay';

import 'font-awesome/css/font-awesome.min.css';
import './assets/fonts/outfit/style.css';
import './App.css';
import { useEffect } from 'react';

// import bootstrap from './assets/styles/bootstrap.css';
// import styles from './assets/styles/style.css';

export const getDuration = (start: moment.Moment | Date, ending: moment.Moment | Date) =>
{
	const d = Math.round((+ending - +start) / 1000 / 60) / 60;
	return (d >= 24 ? Math.floor(d / 24) + ' vrk ' : '') + (d % 24 > 0 ? (d % 24) + ' h' : '');
};

export const dateRoundUp = (date: moment.Moment, interval: number) =>
{
	const roundedMinutes = Math.ceil(date.minute() / interval) * interval;
	return date.clone().minute(roundedMinutes).second(0);
};

function UseResource()
{
	const { id } = useParams<{ id: string; }>() || {};
	return <Redirect to={`/params/${ JSON.stringify({ resource: id }) }`} />;
}

function UseCategory()
{
	const { id } = useParams<{ id: string; }>() || {};
	return <Redirect to={`/params/${ JSON.stringify({ category: id }) }`} />;
}

/* 
	Test: 
	http://localhost:3002/params/{"resource":"0bc90d1c-a2ea-4a6f-bf4f-0c61e0ad48a4","start":1622192400000,"end":1622361600000}/?apikey=3532221017efa403c0699c2a2bb03d05
*/

function UseParams()
{
	const order = useStore(s => s.order);
	const [state, set] = useStated({ redirect: '' });
	const { params } = useParams<{ params: string; }>() || {};

	try
	{
		const json = JSON.parse(params);

		(async () =>
		{
			const company = (await Post({ path: 'company' }))?.data?.[0];
			const offices = await Post({ path: 'office' });
			const resources = await Post({ path: 'resource' });

			order.company = company;
			order.offices = offices;
			order.resources = resources;

			if (json?.resource)
			{
				const resource = _.find(resources?.data, { id: String(json?.resource?.id || json?.resource || '') });
				order.office = _.find(offices?.data, { id: String(resource?.office?.id || resource?.office || '') });
				order.resource = resource;
			}

			if (json?.category)
			{
				order.resources = {
					...resources,
					data: _.filter(resources?.data, f => f?.category?.id === json?.category || f?.category === json?.category),
				};
			}

			if (json?.start && json?.end)
			{
				order.start = new Date(json.start);
				order.end = new Date(json.end);
			}

			console.log('JSON params:', json);
			setStore({ order });

			if (json?.resource)
			{
				if (json?.start && json?.end) return set({ redirect: '/yhteystiedot' });
				return set({ redirect: '/varaus' });
			}
			return set({ redirect: '/' });
		})();
	}
	catch (e)
	{
		console.error('URL-parametreja ei voitu käyttää');
		set({ redirect: '/' });
	}

	const { redirect } = state;
	if (redirect) return <Redirect to={redirect} />;

	return <Loader size={42} />;
}

const pages = {
	'/': <TabOffice />,
	'/valinnat': <TabResource />,
	'/varaus': <TabSchedule />,
	'/yhteystiedot': <TabCustomer />,
	'/vahvistus': <TabConfirm />,
	'/maksu': <TabPayment />,
};

const Transitions = styled.div`
	&.enter {
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.01;

		&.to-left {
			transform: translateX(40px);
		}
		&.to-right {
			transform: translateX(-40px);
		}

		* {
			transition: none !important;
		}
	}

	&.enter-done {
		transition: all 400ms ease;
		transform: translate(0px);
		opacity: 1;
	}
`;

let prevKey = '/';
let slideDirection: 'to-left' | 'to-right' = 'to-right';
const pageKeys = Object.keys(pages);

function AnimatedRouter()
{
	console.log('rendered', pageKeys);

	const location = useLocation();

	if (prevKey !== location.pathname)
	{
		slideDirection = pageKeys.indexOf(prevKey) > pageKeys.indexOf(location.pathname) ? 'to-right' : 'to-left';
		prevKey = location.pathname;
		console.log(location.pathname, slideDirection);
	}

	return (
		<>
			<TabParent>
				<Header />
				<div
					className='d-flex flex-column'
					style={{ flex: 1, height: '100%', overflow: 'hidden auto' }}
				>
					<TransitionGroup
						style={{ flex: 1, height: '100%', overflow: 'hidden auto', display: 'flex', position: 'relative' }}
					>
						<CSSTransition
							unmountOnExit={true}
							mountOnEnter={true}
							timeout={{ appear: 1, enter: 1, exit: 1 }}
							key={location.pathname}
						>
							<Transitions
								className={`d-flex flex-column ${ slideDirection }`}
								style={{ flex: 1, height: '100%', overflow: 'visible' }}
							>
								<Switch location={location}>
									{_.map(pages, (v, k) => (
										<Route
											key={k}
											exact
											path={k}
											children={v}
										/>
									))}
									<Route path='*'>
										<Redirect to='/' />
									</Route>
								</Switch>
							</Transitions>
						</CSSTransition>
					</TransitionGroup>
				</div>
			</TabParent>
		</>
	);
}

function TabReadyId()
{
	const { id } = useParams<{ id: string; }>() || {};
	return (
		<>
			<Header />
			<TabReady id={id} />
		</>
	);
}

export function App()
{
	const [state, set] = useStated({
		prevPath: window.location.href as string,
		loading: true as boolean | 'error',
	});

	// useEffect(() =>
	// {
	// 	(async () =>
	// 	{
	// 		try
	// 		{
	// 			const translations = await Post({
	// 				path: 'translation',
	// 				body: {
	// 					lang: 'fi',
	// 				},
	// 				header: {
	// 					sort: '',
	// 					limit: 10000,
	// 					// fields: {
	// 					// 	lang: true,
	// 					// 	name: true,
	// 					// 	value: true,
	// 					// },
	// 				},
	// 			});

	// 			console.log({ translations });
	// 		}
	// 		catch (e)
	// 		{
	// 			console.error(e);
	// 		}
	// 	})();
	// }, []);

	// const order = useStore(s => s.order);
	const { loading } = state;

	useMount(async () =>
	{
		try
		{
			const company = (await Post({ path: 'company' }))?.data?.[0];
			const offices = await Post({ path: 'office' });
			const resources = await Post({ path: 'resource' });

			setStore({
				order: {
					...getStore().order,
					company,
					offices,
					resources,
				},
			});

			{
				const { maincolor } = company || { maincolor: undefined };
				if (maincolor)
				{
					colorScheme.mainColor = maincolor;
					colorScheme.mainColorDark = maincolor;
					colorScheme.mainColorDarker = maincolor;
					colorScheme.mainColorDarkest = maincolor;
				}
			}

			{
				const { maincolor } = offices?.data?.[0] || { maincolor: undefined };
				if (offices?.data?.length === 1 && maincolor)
				{
					colorScheme.mainColor = maincolor;
					colorScheme.mainColorDark = maincolor;
					colorScheme.mainColorDarker = maincolor;
					colorScheme.mainColorDarkest = maincolor;
				}
			}

			{
				// console.log(bootstrap);
				// const boots = await import('./assets/styles/bootstrap.css');
				// const boots = (await import('assets/styles/bootstrap.css')).default;
				// const urli = new URL('./assets/styles/bootstrap.css', import.meta.url);
				// console.log(urli);

				// let res = bootstrap;
				// let res = await (await fetch('/css/bootstrap.v5.css')).text();
				let res: string = await (await fetch('/css/bootstrap.css')).text();
				res = res.split('28a745').join(colorScheme.mainColor);
				res = res.split('218838').join(colorScheme.mainColorDark);
				res = res.split('1e7e34').join(colorScheme.mainColorDarker);
				res = res.split('1c7430').join(colorScheme.mainColorDarkest);
				const style = document.createElement('style');
				style.innerHTML = res;
				document.body.appendChild(style);
			}

			{
				// let res = styles;
				let res: string = await (await fetch('/css/style.css')).text();
				res = res.split('28a745').join(colorScheme.mainColor);
				res = res.split('218838').join(colorScheme.mainColorDark);
				res = res.split('1e7e34').join(colorScheme.mainColorDarker);
				res = res.split('1c7430').join(colorScheme.mainColorDarkest);
				const style = document.createElement('style');
				style.innerHTML = res;
				document.body.appendChild(style);
			}

			return set({ loading: false });
		}
		catch (e)
		{
			console.error(e);
			return set({ loading: 'error' });
		}
	});

	if (loading !== false)
	{
		return <Loader size={42}>{loading === 'error' && <>Tapahtui virhe, yritä hetken kuluttua uudelleen</>}</Loader>;
	}

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Switch>
				<Route path='/info'>
					<InfoDisplay />
				</Route>
				<Route path='/maksu/:id'>
					<>
						<Header />
						<TabPayment />
					</>
				</Route>
				<Route path='/valmis/:id'>
					<TabReadyId />
				</Route>
				<Route path='/list'>
					<Listing />
				</Route>
				<Route path='/resource/:id'>
					<UseResource />
				</Route>
				<Route path='/category/:id'>
					<UseCategory />
				</Route>
				<Route path='/params/:params'>
					<UseParams />
				</Route>
				<Route path='*'>
					<AnimatedRouter />
				</Route>
			</Switch>
		</BrowserRouter>
	);
}