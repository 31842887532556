import { useState, useEffect } from 'react';

type Hook = () => void;

type BaseStore<State extends Record<string, unknown>> = {
	getStore(): State;
	setStore(partial: Partial<State>): void;
	useStore<S>(selector: (state: State) => S): S;
};

export const createStore = <S extends Record<string, unknown>>(initial: S, name = 'default'): BaseStore<S> =>
{
	const create = <State extends Record<string, unknown>>(initial: State): BaseStore<State> =>
	{
		const hooks = new Set<Hook>();
		const state: State = initial;

		return {
			getStore: () => state,
			useStore: selector =>
			{
				const result = selector(state);
				const [tick, update] = useState(0);

				useEffect(() =>
				{
					const hook = () => update(tick + 1);
					hooks.add(hook);
					return () => void hooks.delete(hook);
				}, [tick]);

				return result;
			},
			setStore: async value =>
			{
				Object.assign(state, { ...state, ...value });
				for (const hook of hooks) hook();
			},
		};
	};

	const stores = ((globalThis as { _stores?: Record<string, BaseStore<S>>; })._stores ??= {});
	return (stores[name] ??= create<S>(initial));
};