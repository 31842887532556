import _ from 'lodash';
import moment from 'moment';
import { Button } from 'components/Button';
import { Post } from 'utils/Api';
import { useMount } from 'hooks/Hooks';
import { SqlDate, ToCurrency } from 'utils/Dates';
import { setStore, useStore } from 'store';
import { BoxContainer, SelectBox } from 'components/SelectBoxes';
import { Redirect } from 'react-router-dom';
import { HeaderItem } from './Blocks/HeaderItem';
import { Picture } from 'components/Picture';

export function TabResource()
{
	const order = useStore(s => s.order);
	const { office, resource, offices, resources, schedules } = order;

	useMount(async () =>
	{
		setStore({
			order: {
				...order,
				schedules: await Post({
					path: 'schedule',
					body: {
						ending: '>=' + SqlDate(moment().subtract(1, 'days')),
						start: '<' + SqlDate(moment().add(30, 'days')),
					},
					header: { limit: 1000 },
				}),
			}
		});
	});

	// let nextOpenTime: moment.Moment = moment(0);
	// for (let i = 0; i < 7; i++) {
	//     const openSplit = _.find(office?.openings, { weekday: (moment().weekday() + i) % 7 })?.opentime?.split(':');
	//     const opentime = moment().add(i, 'day').hour(openSplit?.[0]).minute(openSplit?.[1]);

	//     const closeSplit = _.find(office?.openings, { weekday: (moment().weekday() + i) % 7 })?.opentime?.split(':');
	//     const closetime = moment().add(i, 'day').hour(closeSplit?.[0]).minute(closeSplit?.[1]);

	//     if (moment() < closetime && moment() < opentime) {
	//         nextOpenTime = opentime;
	//         break;
	//     }
	// }

	if (!office?.id) return <Redirect to={`/`} />;
	if (resource?.id) return <Redirect to={`/varaus`} />;

	return (
		<>
			{
				<>
					<div className='text-center d-flex p-0'>
						<HeaderItem>
							<div style={{ height: '125px' }}>
								<Picture src={office?.picture} />
							</div>
							<div>
								<div>{office?.name}</div>
								{Number(offices?.data?.length) > 1 && (
									<div>
										<Button
											onClick={() =>
											{
												setStore({
													order: {
														...order,
														office: undefined, resource: undefined, order_lines: []
													}
												});
											}}
										>
											Vaihda
										</Button>
									</div>
								)}
							</div>
						</HeaderItem>
					</div>

					<BoxContainer>
						{office &&
							schedules?.data !== undefined &&
							_.map(
								_.filter(resources?.data, v => v.office?.id === office?.id),
								(v, k) =>
								{
									if (v?.parents?.length > 0) return false;

									const maxEnd = _.maxBy(
										_.filter(schedules?.data, f => f?.resource?.id === v?.id),
										'ending',
									);

									let reserved = undefined;
									for (const i of _.range(moment(maxEnd?.ending).diff(moment(), 'h')))
									{
										const d = moment().add(i, 'h');
										const found = _.find(
											schedules?.data,
											f => f?.resource?.id === v?.id && d.isBetween(moment(f?.start), moment(f?.ending), 'h', '[]'),
										);
										if (found) reserved = moment(found?.start);
									}

									let freeUntil = undefined;
									for (const i of _.range(moment(maxEnd?.start).diff(moment(), 'h')))
									{
										const d = moment().add(i, 'h');
										const found = _.find(
											schedules?.data,
											f => f?.resource?.id === v?.id && d.isBetween(moment(f?.start), moment(f?.ending), 'h', '[]'),
										);
										if (found)
										{
											freeUntil = moment(found?.ending);
											break;
										}
									}

									return (
										<SelectBox
											key={k}
											// active={ active === v }
											className={'col-6 col-md-4 col-lg-2'}
											// selected={ v === selected }
											// onTouchStart={() => {
											//     setState({ active: v });
											// }}
											// onTouchMove={() => {
											//     setState({ active: undefined });
											// }}
											onClick={() =>
											{
												setStore({
													order: {
														...order,
														resource: v, start: undefined, end: undefined,
													}
												});
											}}
										>
											<div>
												<div>
													<Picture
														src={v?.picture}
														alt={v?.name}
													/>
												</div>
												<div>
													<div className='p-0'>{v?.name}</div>
													<div>
														<small>
															<>Alkaen </>
															{ToCurrency(
																_.reduce(v?.pricefields, (r, f) => (f.price < (r || Infinity) ? f.price : r), undefined) ||
																v?.base_price,
															)}
														</small>
														{(freeUntil && (
															<span
																className='badge badge-pill font-weight-normal pull-right mt-1 text-white w-100 p-1'
																style={{ background: '#55e' }}
															>
																Varattavissa {moment(freeUntil).format('DD.MM. HH:mm')}
															</span>
														)) ||
															(reserved && (
																<span
																	className='badge badge-pill font-weight-normal pull-right mt-1 text-white w-100 p-1'
																	style={{ background: 'orange' }}
																>
																	Varattavissa {reserved?.format('DD.MM. HH:mm')} asti
																</span>
															)) || (
																<span className='badge badge-pill font-weight-normal pull-right mt-1 text-white bg-success w-100 p-1'>
																	Varattavissa heti
																</span>
															)}
													</div>
												</div>
											</div>
										</SelectBox>
									);
								},
							)}
					</BoxContainer>
				</>
			}
		</>
	);
}
