import _ from 'lodash';
import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import { colorScheme } from 'utils/ColorScheme';
import { Picture } from './Picture';

export const BoxContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	/* justify-content: center; */
	/* margin: 0px -2px 0px 0px; */
	margin-right: 1px;

	@media screen and (max-width: 768px) {
		margin: 0px -1px;
	}
`;

export const SelectBox = styled.div<{ selected?: boolean; active?: boolean; }>`
	min-height: 140px;
	padding: 1px 0px 0px 1px !important;
	outline: 0;
	border: 0;
	cursor: pointer;

	/* padding: 10px 5px 5px 5px; */
	/* box-shadow: 1px 1px 1px 0px #00000044; */
	/* margin: -1px -1px 0px 0px; */

	text-align: center;
	background: #fff;
	box-shadow: 1px 1px #ccc, 1px 0px #ccc inset, 0px 1px #ccc inset;

	div > img:last-child {
		transition: all 150ms ease-in-out;
	}

	:hover,
	:active,
	:focus {
		transition: all 100ms ease-out;
		box-shadow: 1px 1px #ccc, 1px 0px #ccc inset, 0px 1px #ccc inset, 0px 0px 8px 1px #00000022;
		div > img:last-child {
			transform: scale(1.1);
		}
	}

	${ p =>
		p.active &&
		css`
			transition: all 100ms ease-out;
			box-shadow: 1px 1px #ccc, 1px 0px #ccc inset, 0px 1px #ccc inset, 0px 0px 8px 1px #00000022;
			div > img:last-child {
				transform: scale(1.1);
			}
		`}

	> div {
		height: 100%;
		pointer-events: none;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		cursor: pointer;

		> div {
			justify-content: center;

			${ p =>
		p.selected &&
		css`
					background: #${ colorScheme.mainColor }22;
				`}
		}

		${ p =>
		p.selected &&
		css`
				/* border: 1px solid #${ colorScheme.mainColor }; */
			`}

		> div:first-child {
			max-height: 85px;
			/* flex: 1; */
			position: relative;
			justify-content: center;
			align-items: center;
			display: flex;
			min-height: 70px;
			/* height: 110%; */
			overflow: hidden;
		}

		/* > div:first-child > img:first-child {
				position: absolute;
				max-width: 100%;
				border-radius: 2px;
				filter: blur(3px);
		}
		> div:first-child > img:nth-child(2) {
				position: absolute;
				max-height: 100%;
				max-width: 200%;
				filter: blur(3px);
				border-radius: 3px;
				transition: height 100ms ease-in;
			
		} */
		> div:first-child > img {
			mix-blend-mode: multiply;
			/* position: absolute; */
			border-radius: 2px;
			transition: all 100ms ease-in;
			/* height: 100%; */
			max-width: 100%;
			/* max-height: 125%; */
			max-height: 100%;
		}

		> div:first-child > div:first-child {
			position: absolute;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		> div:first-child > div:last-child {
			position: absolute;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: auto 110%;
			background-position: center;
		}

		> div:last-child {
			flex: 1;
			z-index: 1;
			background: #fff;

			/* ${ p =>
		p.selected &&
		css`
					background: #${ colorScheme.mainColor }22;
				`} */

			display: flex;
			flex-direction: column;

			/* justify-content: flex-end; */
			padding: 8px;

			> div {
				padding: 4px 6px;
				line-height: 105%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
`;

type Props = {
	value: any;
	options: any;
	style?: React.CSSProperties;
	onChange: (value: any) => void;
	info: (item: any) => ReactNode;
	badge?: (item: any) => ReactNode;
};

export function SelectBoxes(props: Props)
{
	const { value, options, info, badge, onChange, style } = props;
	const selected = options?.[options?.indexOf(value)];

	return (
		<div
			className='row'
			style={{ ...style }}
		>
			{/* <div className='col-12 text-center'>
				{ 
					// options ? title : 'Ei valintoja'
					options ? title : ''
				}
			</div> */}
			<div className='col-12'>
				<BoxContainer>
					{_.map(options, (v, k) => (
						<SelectBox
							key={k}
							className={'col-6 col-md-4 col-lg-2'}
							selected={v === selected}
							// onTouchStart={() =>
							// {
							// 	setState({ active: v });
							// }}
							// onTouchMove={() =>
							// {
							// 	setState({ active: undefined });
							// }}
							onClick={() => onChange?.(v)}
						>
							<div>
								<div>
									<Picture
										src={v?.picture}
										alt={v?.name}
									/>
								</div>
								<div>
									<div className='p-0'>{v?.name}</div>
									<div>
										<small>{info?.(v)}</small>
										{badge?.(options[k])}
									</div>
								</div>
							</div>
						</SelectBox>
					))}
				</BoxContainer>
			</div>
		</div>
	);
}
