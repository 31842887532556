import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { useStore } from 'store';
import { getDuration } from 'App';
import { Image } from 'components/Image';
import { Entry, Post, Put } from 'utils/Api';
import { TabParent } from './Blocks/TabParent';
import { useMount, useStated } from 'hooks/Hooks';
import { HeaderItem } from './Blocks/HeaderItem';
import { Picture } from 'components/Picture';

const CancelDiv = styled.div`
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
`;

type Props = {
	id: string;
};

export function TabReady(props: Props)
{
	const [state, set] = useStated({
		schedule: undefined as void | Entry,
		loading: true,
		cancel: {
			open: false,
			reason: '',
			confirm: false,
			loading: false,
		},
	});

	const order = useStore(s => s.order);
	const { customer } = order;
	const { schedule, cancel, loading } = state;

	useMount(async () =>
	{
		const { id } = props;

		const offices = await Post({ path: 'office' });
		const resources = await Post({ path: 'resource' });
		const schedule = (await Post({ path: 'schedule', body: { id } }))?.data?.[0];

		if (!schedule) return;
		schedule.office = _.find(offices?.data, { id: schedule?.office?.id });
		schedule.resource = _.find(resources?.data, { id: schedule?.resource?.id });
		set({ schedule, loading: false });
	});

	return (
		(!schedule && (
			<TabParent>
				<div className='row p-3'>
					{!loading ? (
						<div className='col-md-6 mt-0'>
							<p className='mt-2 mb-3'>
								<b style={{ fontSize: '1.25em' }}>Virhe</b>
							</p>
							Varausta ei löytynyt
						</div>
					) : (
						<div className='col-md-6 mt-0'>
							<p className='mt-2 mb-3'>
								<b style={{ fontSize: '1.25em' }}>Hetki...</b>
							</p>
							Haetaan varauksen tietoja...
						</div>
					)}
				</div>
			</TabParent>
		)) || (
			<TabParent>
				<HeaderItem>
					<div style={{ height: '125px' }}>
						<Picture src={schedule?.office?.picture || schedule?.company?.picture} />
					</div>
					<div>
						<div className='text-center'>{schedule?.office?.name}</div>
					</div>
				</HeaderItem>

				<div className='row p-3'>
					<div className='col-md-6 mt-0'>
						<p className='mt-2 mb-3'>
							<b style={{ fontSize: '1.25em' }}>
								{schedule?.payment_status === 'paid' ? <>Varaus maksettu</> : <>Varaus vahvistettu</>}
							</b>
						</p>
						<div className='reservationinfo'>
							Kiitos, varaus on nyt tehty!
							<br />
							<br />
							Varauksen tiedot on lähetetty antamaasi sähköpostiosoitteeseen.
							<br />
							<br />
							<b>{schedule?.resource?.name}</b>
							<br />
							Alkaa: {moment(schedule?.start).format('HH:mm D.M.Y')}
							<br />
							Päättyy: {moment(schedule?.ending).format('HH:mm D.M.Y')}
							<br />
							Varauksen kesto: {getDuration(new Date(schedule?.start), new Date(schedule?.ending))}
							<br />
							<br />
							<b>Yhteystiedot:</b>
							<br />
							{schedule?.office?.name}
							<br />
							{schedule?.office?.address}
							<br />
							{schedule?.office?.postal} {schedule?.office?.city}
							<br />
							<br />
							{schedule?.office?.phone}
							<br />
							{schedule?.office?.email}
							<br />
							{/* Voit nyt sulkea sivun. */}
						</div>
					</div>
					<div className='col-md-6 mt-0' style={{ overflow: 'hidden', flex: 1 }}>
						<hr />
						<Image
							src={schedule?.resource?.picture}
							width={500}
							style={{ maxWidth: '100%', maxHeight: '100%', mixBlendMode: 'multiply' }}
						/>

						{false && schedule?.payment_status !== 'paid' && (
							<div>
								<br />

								<button
									className='btn btn-secondary mb-2'
									onClick={() =>
									{
										cancel.open = !cancel.open;
										set({ cancel });
									}}
								>
									Varauksen peruuttaminen
								</button>

								{cancel.open &&
									((moment().isSameOrAfter(moment(schedule?.start).subtract(24, 'h')) && (
										<div>Varausta ei voi enää peruuttaa verkossa, ota yhteyttä.</div>
									)) || (
											<CancelDiv>
												<input
													type='text'
													spellCheck={false}
													autoFocus={false}
													className='form-control mb-2'
													placeholder='Vapaaehtoinen syy peruutukseen'
													style={{ fontSize: '0.85rem' }}
													onChange={e =>
													{
														cancel.reason = e.target.value;
														set({ cancel });
													}}
												/>
												<div className='row mb-2'>
													<div className='col-6 col-sm-4 pr-0' style={{ alignSelf: 'center' }}>
														Vahvistan peruutuksen
													</div>
													<div className='col-6 col-sm-8'>
														<label className='switch m-0' style={{ alignSelf: 'center' }}>
															<input
																type='checkbox'
																autoFocus={false}
																checked={customer?.iscompany}
																onChange={e =>
																{
																	cancel.confirm = e.target.checked;
																	set({ cancel });
																}}
															/>
															<span className='switchslider round' />
														</label>
													</div>
												</div>
												<button
													className='btn btn-danger'
													disabled={!cancel.confirm}
													onClick={async e =>
													{
														e.preventDefault();
														if (!cancel.confirm) return;
														try
														{
															const res = await Put({
																path: 'schedule',
																body: {
																	id: schedule?.id,
																	reason: cancel.reason,
																	active: 'deleted',
																},
															});

															if (!res?.id) throw Error(`Varaus ei tallentunut`);
															alert('Varaus peruutettu');
														}
														catch (e)
														{
															alert(`Tapahtui virhe: ${ String(e) }`);
														}
													}}
												>
													Peruuta varaus
												</button>
											</CancelDiv>
										))}
							</div>
						)}
					</div>
				</div>
			</TabParent>
		)
	);
}