import _ from 'lodash';
import moment from 'moment';

export const SqlDate = (date: moment.Moment | Date): string =>
{
	if (!moment.isMoment(date)) date = moment(date);
	return date.format('YYYY-MM-DD HH:mm:ss');
};

export const ToCurrency = (price: number, noSign = false): string =>
{
	if (!_.isFinite(price)) return '';

	return _.toNumber(price || 0).toLocaleString('fi-FI', { minimumFractionDigits: 2 }) + (noSign ? '' : ' €');
};
