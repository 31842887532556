import type { Assoc } from './Assoc';

const { location } = window || {};

const isLocalhost = Boolean(
	location.hostname === 'localhost' ||
	location.hostname === '[::1]' ||
	location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/),
);

export const DEVELOPMENT = process.env.NODE_ENV === 'development' || isLocalhost;
export const BackendURL = DEVELOPMENT ? 'http://localhost:5000' : 'https://hallinta.oitis.fi/endpoint';

const urlParams = () =>
{
	// const { href } = location;
	// const matches = [...href.matchAll(/[?&]+([^=&]+)=([^&]*)/gi)];
	// return matches.reduce<Record<string, string>>((r, v) => void (r[v[1]] = v[2]) ?? r, {});
	return Object.fromEntries(new URL(location.href).searchParams);
};

export const ApiStore = {
	apikey: String(urlParams()?.apikey || ''),
};

if (isLocalhost && !ApiStore.apikey)
{
	// ApiStore.apikey = 'd6902ff2da5180a19915bf3fd7ace10d'; // Kampaamo (testi)
	ApiStore.apikey = '3532221017efa403c0699c2a2bb03d05'; // Paljuparoni
	// ApiStore.apikey = '843f0aba6d71dd694b41115dea2bf600'; // Vetopeli
	// ApiStore.apikey = '56aefd6a39789a777ec94902302cc482'; // Satama
	// ApiStore.apikey = 'efb63b90d9c1c912c1be94e4bcddec04'; // Naantali
	// ApiStore.apikey = '5e85541a5c335a97eda4670742a57f86'; // Infonäyttö
	// ApiStore.apikey = '5bc37bd3b0056be947284f8a64ac1d37'; // Mantunkulma
	// ApiStore.apikey = '2acf0cedd3c09070727608df8ef98516'; // Terapeutti
}

export type RequestOptions = {
	path: string;
	method: 'POST' | 'PUT';
	body?: Assoc<any>;
	header?: {
		limit?: number;
		offset?: number;
		sort?: string;
		dir?: 'desc' | 'asc';
	};
	extra?: {
		signal: AbortSignal;
	};
};

export async function request(opts: RequestOptions): Promise<Response>
{
	const { method, path, body, extra, header } = opts || {};
	const token = ApiStore.apikey;

	const res = await fetch(`${ BackendURL }/${ path }`, {
		method,
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			...(token && { Authorization: token }),
			...(header as Record<string, string>),
		},
		body: JSON.stringify(body),
		...extra,
	});

	if (res.status !== 200) throw res;
	return res;
}

export type Entry = Assoc<any> & { id: string; };

export type PostOptions = Omit<RequestOptions, 'method'>;
export type PostResponse<T = Entry> = { data: T[]; count: number; };

export async function Post<T = PostResponse>(opts: PostOptions): Promise<T>
{
	const { path, body, header, extra } = opts || {};
	try
	{
		const res = await request({ method: 'POST', path, body, header, extra } as RequestOptions);
		const json = await res.json();
		return json;
	}
	catch (e)
	{
		if (e instanceof Response) throw await e.json();
		throw { error: 'Tapahtui virhe, yritä hetken kuluttua uudelleen' };
	}
}

export type PutOptions = Omit<RequestOptions, 'method'> & { body: Assoc<any>; };
export type PutResponse = { id: string; };

export async function Put<T = PutResponse>(opts: PutOptions): Promise<T>
{
	const { path, body, header, extra } = opts || {};
	try
	{
		const res = await request({ method: 'PUT', path, body, header, extra } as RequestOptions);
		const json = await res.json();
		return json;
	}
	catch (e)
	{
		if (e instanceof Response) throw await e.json();
		throw { error: 'Tapahtui virhe, yritä hetken kuluttua uudelleen' };
	}
}