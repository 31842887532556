import styled, { css } from 'styled-components';
import { useStated } from 'hooks/Hooks';
import { BackendURL } from 'utils/Api';
import { colorScheme } from 'utils/ColorScheme';
import { Loader } from './Loader';

const BgImg = styled.img`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	transform: scale(1.2);
	filter: blur(10px) brightness(95%);
`;

const Img = styled.img`
	z-index: 1;
	position: relative;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	/* mix-blend-mode: multiply; */
`;

const PictureContainer = styled.div<{ loaded: boolean; color: string; }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-bottom: 1px solid #${ p => p?.color }22;

	img {
		opacity: 0;
		transition: opacity 200ms ease-in-out;
		${ p =>
		p?.loaded &&
		css`
				opacity: 1;
			`}
	}

	.anticon,
	i,
	svg {
		color: #${ p => p?.color };
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		max-height: 50%;

		> svg {
			width: 100%;
			height: 100%;
		}
	}

	canvas {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	small {
		opacity: 0.5;
	}

	:after {
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(0deg, #${ p => p?.color }16 0%, #${ p => p?.color }04 80%);
	}
`;

type Props = {
	src?: string;
	alt?: string;
	size?: number;
	className?: string;
};

export function Picture(props: Props)
{
	const [state, set] = useStated({
		error: false as boolean,
		loaded: false as boolean,
		bgColor: undefined as void | string,
	});

	const { src, alt, size } = props;
	const { error, loaded, bgColor } = state;
	const url = `${ BackendURL }/thumb/?w=${ size || 400 }&u=${ src }`;

	const onLoad = (image: HTMLImageElement) =>
	{
		try
		{
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d', { willReadFrequently: true });
			if (!ctx) return;

			const getPixel = (x: number, y: number) => ctx.getImageData(x, y, 1, 1)?.data;
			const colors: Uint8ClampedArray[] = [];
			const size = 64;
			const offset = 2;

			const w = (canvas.width = size);
			const h = (canvas.height = size);
			ctx.clearRect(0, 0, w, h);
			ctx.drawImage(image, 0, 0, w, h);

			colors.push(getPixel(offset, offset));
			colors.push(getPixel(w - 1 - offset, offset));
			colors.push(getPixel(offset, h - 1 - offset));
			colors.push(getPixel(w - 1 - offset, h - 1 - offset));
			canvas.remove();

			const alpha = colors.find(v => (v?.[3] || 0) < 32);
			const solid = colors.find((v, k) =>
			{
				const n = (k + 1) % 4;
				if (v?.[0] !== colors[n]?.[0]) return false;
				return true;
			});

			const [color] = colors;
			if (!color) return;
			if (alpha) return set({ bgColor: 'transparent' });
			if (solid) return set({ bgColor: `rgba(${ color.join(',') })` });
		}
		catch {}
	};

	return (
		<PictureContainer
			color={colorScheme.mainColor}
			loaded={loaded}
			style={{
				...(bgColor && { background: bgColor }),
			}}
		>
			{Boolean(src && !error) && (
				<>
					{Boolean(loaded && !bgColor) && <BgImg src={url} crossOrigin='anonymous' alt={alt || 'Kuva'} />}
					<Img
						src={url}
						loading='lazy'
						crossOrigin='anonymous'
						alt={alt || 'Kuva'}
						onError={() => set({ error: true })}
						onLoad={e =>
						{
							onLoad(e.currentTarget);
							set({ loaded: true });
						}}
					/>
				</>
			)}
			{Boolean(!src || error) && <small>Ei kuvaa</small>}
			{Boolean(src && !loaded && !error) && <Loader />}
		</PictureContainer>
	);
}