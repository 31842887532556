import React from 'react';
import { useStated } from 'hooks/Hooks';

// const SlideContainer = styled.div`
//     scrollbar-width: none; /* Firefox */
//     -ms-overflow-style: none;  /* Internet Explorer 10+ */
// 	::-webkit-scrollbar { /* WebKit */
// 		width: 0;
// 	}

// 	overflow: hidden auto;
// 	position: relative;
// 	flex: 1;
// 	/* min-width: 100%; */
// 	width: 100%;
// 	max-width: 100%;
// 	max-height: 100%;
// 	height: 100%;
// 	display: flex;
// 	flex-direction: column;
// `;

type Props = {
	children: (slide?: any, content?: any) => any;
};

export function Slide(props: Props)
{
	const [state, set] = useStated({
		animate: 0,
	});

	let duration = 500;
	let oldChildren: any = [];
	let timeout: any = undefined;
	let contentCache: any = undefined;
	let contentIndex = 0;

	let running = false;
	let benchmark = Date.now();

	const { animate } = state;
	const { children } = props;

	const cache = async (content: any, contentIndex: number) =>
	{
		if (running)
			contentCache = React.cloneElement(
				content,
				{
					key: 'slider' + contentIndex,
					className: content?.props?.className + ' slider-page1',
					onAnimationEnd: () =>
					{
						// oldChildren = [];
						// contentCache = undefined;
						running = false;
						set({ animate: 0 });
					},
				},
				[content?.props?.children, oldChildren?.[contentIndex - 1]],
			);
		// if (!oldChildren[contentIndex - 1])
		else
			oldChildren[contentIndex - 1] = React.cloneElement(content, {
				key: 'sliderpage' + contentIndex,
				className: content?.props?.className + ' slider-page2',
			});
	};

	return children(
		async (amount?: any) =>
		{
			benchmark = Date.now();
			running = true;
			set({ animate: amount || 100 });

			if (timeout) clearInterval(timeout);
			timeout = setTimeout(() =>
			{
				if (running)
				{
					// oldChildren = [];
					running = false;
					set({ animate: 0 });
				}
			}, duration * 1.5);
		},
		(content?: any) =>
		{
			contentIndex++;
			cache(content, contentIndex);

			if (!running)
			{
				return content;
			}

			// console.log(oldChildren[contentIndex - 1]);

			// console.log('rerender', Date.now());
			console.log('rerender', contentIndex, 'took', Date.now() - benchmark, 'ms');
			// benchmark = Date.now();

			// return contentCache;

			return (
				<>
					<style>{`
							@keyframes slidermove {
								from { transform: translateX(${ animate }%); }
								to { transform: translateX(0%); }
							}
							.slider-page1 {
								animation: slidermove ${ duration }ms forwards;
								animation-timing-function: ease-in-out;
								transition: none;
								position: relative;
								overflow: initial;

							}
							.slider-page1 * { transition: none; }

							.slider-page2 {
								transform: translateX(${ -animate }%);
								position: absolute;
								width: 100%;
								height: 100%;
								transition: none;
								top: 0px;
								order: -1;
							}
							.slider-page2 * { transition: none; }

						`}</style>
					{contentCache}
					{/* {
							React.cloneElement(contentCache, {}, [
								content?.props?.children,
								oldChildren?.[contentIndex - 1],
							])
						} */}
				</>
			);
		},
	);
}
