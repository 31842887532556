import _ from 'lodash';
import styled from 'styled-components';
import { setStore, useStore } from 'store';
import { useMount, useStated } from 'hooks/Hooks';
import { Redirect, useParams } from 'react-router-dom';
import { SqlDate, ToCurrency } from 'utils/Dates';
import { Entry, Post, Put } from 'utils/Api';
import { TabParent } from './Blocks/TabParent';
import { SelectBox } from 'components/SelectBoxes';
import { Loader } from 'components/Loader';
import type { Assoc } from 'utils/Assoc';

const AlertBox = styled.div`
	margin: 20px 20px 0 20px;
	padding: 7px 14px;
	font-size: 14px;
	text-align: center;

	text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
	background: #fcf8e3;
	border: 1px solid #c0985380;
	border-radius: 4px;
	color: #af8338cc;
	/* box-shadow: 0px 0px 2px -1px #00000020; */

	b {
		color: #af8338;
		font-weight: 500;
		font-size: 16px;
		display: block;
		margin-bottom: 2px;
	}
`;

export const TabPayment = () =>
{
	const { id } = useParams<{ id: string; }>() || {};

	type PaymentSchedule = Entry & { payment_status: string; calcprice: number; };
	const [state, set] = useStated({
		redirect: '',
		selected: '',
		paymentSchedule: undefined as void | PaymentSchedule,
		paymentMethods: undefined as void | Assoc<any>[],
	});

	const [order, appStatus] = useStore(s => [s.order, s.appStatus]);
	const { start, end, office, resource, customer, calcprice } = order;
	const { formValid, agreed, payment_schedule_id } = appStatus || {};
	const { redirect, selected, paymentSchedule, paymentMethods } = state;

	useMount(async () =>
	{
		if (id)
		{
			const schedule = (await Post({ path: 'schedule', body: { id } }))?.data?.[0];
			if (!schedule)
			{
				console.error('Varausta ei löytynyt');
				alert(`Varausta ei löytynyt`);
				return set({ redirect: `/` });
			}

			if (schedule?.payment_status === 'paid') return set({ redirect: `/valmis/${id}` });
			set({ paymentSchedule: schedule as PaymentSchedule });
		}

		const paymentMethods = await Post<any>({ path: 'payment_methods' });
		set({ paymentMethods });
	}, [id]);

	if (!id)
	{
		const validCustomer = formValid && agreed && customer?.name && customer?.phone && customer?.email;
		if (!validCustomer) return <Redirect to={'/yhteystiedot'} />;

		const validSchedule = office && resource && start && end;
		if (!validSchedule) return <Redirect to={'/vahvistus'} />;
	}
	if (redirect) return <Redirect to={redirect} />;

	const amount = Number(calcprice?.total || paymentSchedule?.calcprice);
	const methods = _.filter(paymentMethods || [], v =>
	{
		if (amount * 100 < v?.min_amount) return false;
		if (amount * 100 > v?.max_amount) return false;
		return true;
	});

	return (
		<TabParent>
			{
				<div
					className='row p-0 m-0'
					style={{
						justifyContent: 'center',
					}}>
					{id && (
						<AlertBox>
							<b>Maksu epäonnistui</b>
							<span>Yritä hetken päästä uudelleen tai valitse toinen maksutapa</span>
						</AlertBox>
					)}

					<div className='w-100 text-center p-2' style={{ background: '#f8f8f8' }}>
						<div className='pt-3' style={{ fontSize: '18px' }}>
							Summa: {ToCurrency(amount)}
						</div>
						<div className='pb-3'>
							<>Maksamalla hyväksyt </>
							<a href='https://oitis.fi/kayttoehdot-maksava-asiakas' target='_blank'>
								maksuehdot
							</a>
						</div>
					</div>

					{(Boolean(!paymentMethods || selected) && <Loader size={42} />) ||
						(!methods?.length && <div>Ei maksutapoja</div>) ||
						_.map(methods || [], (v, k) =>
						{
							return (
								<SelectBox
									key={k}
									style={{ minHeight: '100px' }}
									className='col-4 col-md-4 col-lg-2'
									onClick={async () =>
									{
										console.log(v);
										if (selected) return;

										set({ selected: v.selected_value });
										let schedule_id = payment_schedule_id || id;

										if (!schedule_id && start && end)
										{
											try
											{
												const res = await Put({
													path: 'schedule',
													body: {
														customer,
														start: SqlDate(start),
														ending: SqlDate(end),
														resource: resource?.id,
														office: office?.id,
														order_lines: order?.order_lines,
														infotext: order?.infotext,
														payment_type: 'epayment',
													},
												});

												if (!res?.id) throw Error('Varaus ei tallentunut');
												schedule_id = res?.id;
												setStore({ appStatus: { ...appStatus, payment_schedule_id: res?.id } });
											} catch (e)
											{
												alert(`Tapahtui virhe: ${String(e)}`);
												set({ selected: '' });
											}
										}

										const res = await Post<{ url: string; }>({
											path: 'payment_create',
											body: {
												selected: v.selected_value,
												schedule_id,
											},
										});

										window.open(res.url, '_top');
									}}>
									<div>
										<div>
											<img src={v?.img} alt={v?.name} />
										</div>
										<div>
											<div className='pb-0'>{v?.name}</div>
										</div>
									</div>
								</SelectBox>
							);
						})}
				</div>
			}
		</TabParent>
	);
};
