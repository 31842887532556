import _ from 'lodash';
import moment from 'moment';
import hash_sum from 'hash-sum';
import styled, { css } from 'styled-components';
import { useStore, setStore } from 'store';
import { colorScheme } from 'utils/ColorScheme';
import { Swipe } from './Swipe';
import { SqlDate } from 'utils/Dates';
import { Entry, Post } from 'utils/Api';
import { Slide } from 'components/Slide';
import { useMount, useStated } from 'hooks/Hooks';
import { usePrice } from 'components/UsePrice';
import { Loader } from 'components/Loader';
import type { Assoc } from 'utils/Assoc';

const Timeblocks = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	/* padding: 16px 0px; */
	/* transition: all 150ms ease-in-out; */

	/* border-radius: 10px; */
	/* overflow: hidden; */
`;

const Weekday = styled.div<{ selected?: boolean; reserved?: boolean; }>`
	width: 14.285%;
	padding: 10px 5px;
	border-bottom: 1px solid #00000011;
	text-align: center;
`;

// StyledComponent<"div", any, {
// 	selected?: boolean;
// 	reserved?: boolean;
// 	past?: boolean;
// }, never>

const Dateblock = styled.div<{ selected?: boolean; reserved?: boolean; past?: boolean; }>`
	position: relative;
	width: 14.285%;
	margin-bottom: 3px;
	border-color: transparent;
	border-bottom-color: #fff;
	padding: 3px;
	text-align: center;
	background: #fff;
	height: 64px;
	/* box-shadow: 1px 1px 2px #e1e1e1; */
	transition: all 200ms ease-in-out;

	${ props =>
		!props.reserved &&
		!props.past &&
		css`
			cursor: pointer;

			@media (hover: hover) and (pointer: fine) {
				:hover {
					background: #${ colorScheme.mainColor }1F;
				}
			}

			:active {
				background: #${ colorScheme.mainColor }77;
			}
		`}

	${ p =>
		p.selected &&
		css`
			color: #${ colorScheme.mainColorDark }!important;
			background: #${ colorScheme.mainColor }44!important;
		`}

	${ p =>
		p.reserved &&
		css`
			background: #00000030 !important;
		`}

	${ p =>
		p.past &&
		css`
			background: #0000000f !important;
		`}
`;

const CalendarHeader = styled.div<{ selected?: boolean; reserved?: boolean; }>`
	position: sticky;
	top: -2px;
	min-width: 100%;
	display: flex;
	flex-wrap: nowrap;
	background: #fff;
	z-index: 1;
	box-shadow: #00000020 0px 10px 10px -10px;

	button {
		height: 100%;
		outline: 0;
		border: 0;
		padding: 5px 10px;
	}
`;

export function CalendarContainer()
{
	usePrice();
	const order = useStore(s => s.order);
	const { start, end, resource, schedules } = order;

	const [state, set] = useStated({
		month: start ? moment(start) : moment(),
		priceCache: {} as Assoc<any>,
		priceTable: undefined as void | Assoc<any>,
		prices: undefined as void | Assoc<any>,

		scrollTop: 0,
		swipePreview: 0,
		slide: 0,
	});

	const { month, swipePreview, priceTable, prices } = state;
	const rangeStart = moment(month).startOf('month').startOf('week');

	useMount(async () =>
	{
		console.log('Fetch price blocks');

		if (!resource) return;

		(async () =>
		{
			// setStore({ order: { ...order, schedules: undefined } });

			order.schedules = await Post({
				path: 'schedule',
				body: {
					ending: '>=' + SqlDate(moment(rangeStart).subtract(1, 'd')),
					start:
						'<=' +
						SqlDate(
							moment(rangeStart)
								.add(7 * 6, 'd')
								.add(1, 'd'),
						),
					resource: resource?.id || resource,
				},
				header: { limit: 1000 },
			});
			setStore({ order });
		})();

		(async () =>
		{
			const getPriceBlocks = async (resource: Entry, start: moment.Moment, ending: moment.Moment, accumulate = true) =>
			{
				if (!resource || !start || !ending) return;

				const { priceCache } = state;
				const cacheTime = Math.floor(Date.now() / 1000 / 60);
				const cacheKey = hash_sum({ cacheTime, resource, start, ending, accumulate });
				if (priceCache[cacheKey]) return priceCache[cacheKey];

				const data = await Post({
					path: 'get_price_blocks', body: {
						start: SqlDate(start),
						ending: SqlDate(ending),
						resource: String(resource?.id || resource || ''),
						accumulate,
					}
				});

				priceCache[cacheKey] = data;
				set({ priceCache });
				return data;
			};

			if (start)
			{
				set({ prices: undefined });
				const prices = await getPriceBlocks(resource, moment(start), moment(rangeStart).add(7 * 6, 'd'), true);
				set({ prices });
			}

			set({ priceTable: undefined });
			const priceTable = await getPriceBlocks(resource, moment(rangeStart), moment(rangeStart).add(7 * 6, 'd'), false);
			return void set({ priceTable });
		})();
	}, [+rangeStart, +start]);

	return (
		<>
			<Slide
				children={(slide, content) => (
					<>
						<CalendarHeader>
							<div style={{ display: 'flex', minWidth: '100%', padding: '6px 4px' }}>
								{
									<button
										className='btn btn-light'
										style={{
											...(+moment(month).startOf('month') <= +moment().startOf('month') && {
												visibility: 'hidden',
												pointerEvents: 'none',
											}),
										}}
										onClick={() =>
										{
											slide(-100);
											set({ month: moment(month).subtract(1, 'month') });
										}}
									>
										<i className='fa fa-chevron-left' />
									</button>
								}
								{content(
									<div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>{_.upperFirst(month.format('MMMM Y'))}</div>,
								)}
								<button
									className='btn btn-light'
									onClick={() =>
									{
										slide(100);
										set({ month: moment(month).add(1, 'month') });
										// setState({ slide: 100 });
										// setTimeout(()=>{
										//     setState({ slide: 0 });
										// }, 10);
									}}
								>
									<i className='fa fa-chevron-right' />
								</button>
							</div>
						</CalendarHeader>
						<Swipe
							onMove={x =>
							{
								const allow = !(x > 0 && +moment(month).startOf('month') <= +moment().startOf('month'));
								if (allow)
								{
									const ox = x > 0 ? 1 : x < 0 ? -1 : 0;
									if (ox !== swipePreview) set({ swipePreview: ox });
								}
								return allow;
							}}
							onEnd={x =>
							{
								if (x <= -1)
								{
									const c = moment(month).add(1, 'month');
									set({ month: c });
									set({ swipePreview: 0 });
									return true;
								}
								if (x >= 1)
								{
									const c = moment(month).add(-1, 'month');
									if (+month < Date.now()) return false;
									set({ month: c });
									set({ swipePreview: 0 });
									return true;
								}
								set({ swipePreview: 0 });
								return false;
							}}
						>
							<>
								{content(
									<div style={{ display: 'flex', minWidth: '100%' }}>
										{_.map(_.range(7), v =>
										{
											return <Weekday key={v}>{_.upperFirst(moment().startOf('w').add(v, 'd').format('ddd'))}</Weekday>;
										})}
									</div>,
								)}
								{content(
									!priceTable ? (
										<Loader size={42} />
									) : (
										<Timeblocks>
											{_.map(_.range(7 * 6), index =>
											{
												const d = moment(rangeStart).add(index, 'd');

												const block = _.find(priceTable, f => moment(f?.start).isSameOrAfter(d, 'd'));

												// const ds = moment(block?.start);
												const ds = moment.min(moment(priceTable?.[0]?.start).add(index, 'd'), moment(block?.start));

												const price =
													start && moment(start).isBefore(ds) &&
													_.find(prices || {}, f => moment(f?.ending).isSameOrAfter(moment(block?.start), 'd'));

												const de = !price
													? undefined
													: moment.min(moment(priceTable?.[0]?.ending).add(index - 1, 'd'), moment(block?.ending));

												const selected = Boolean(
													start && ds.isSameOrAfter(start) && (end && de ? de.isSameOrBefore(end) : ds.isSameOrBefore(start)),
												);

												const past = moment(block?.start).isBefore(moment());

												const reserved = _.find(
													schedules?.data,
													f =>
														f?.resource?.id === resource?.id &&
														moment(f?.start).isSameOrBefore(de || moment(block?.ending)) &&
														moment(f?.ending).isSameOrAfter(ds),
												);

												const overlap =
													start &&
													_.find(
														schedules?.data,
														f =>
															f?.resource?.id === resource?.id &&
															moment(f?.start).isSameOrBefore(de || moment(block?.ending)) &&
															moment(f?.ending).isSameOrAfter(start),
													);

												const active = Boolean(!past && !reserved && !overlap);

												return (
													<Dateblock
														key={index}
														selected={selected}
														past={past}
														reserved={!active}
														style={{
															...(start && ds.isSame(start) && { borderRadius: '10px 0 0 10px', borderLeft: '3px solid #fff' }),
															...(de && end && de.isSame(end) && { borderRadius: '0 10px 10px 0', borderRight: '3px solid #fff' }),
															...(!moment(ds).isSame(month, 'month') && { filter: 'opacity(0.5)' }),
														}}
														onClick={async () =>
														{
															if (!active) return;
															if (start && !moment(ds).isSameOrBefore(start, 'd') && !price?.price) return;

															if (!start || (end && !de && !moment(start).isSame(ds)))
															{
																console.log('click 1');
																order.start = ds.toDate();
																order.end = undefined;
																order.calcprice = undefined;
																return setStore({ order });
															}

															if (start && !end && de)
															{
																console.log('click 2');

																// const scrollingElement = (document.scrollingElement || document.body);
																// scrollingElement.scrollTo({ left: 0, top: scrollingElement.scrollHeight, behavior: 'smooth' });

																order.end = de.toDate();
																return setStore({ order });
															}

															console.log('click 3');
															set({ prices: undefined });

															order.start = undefined;
															order.end = undefined;
															order.calcprice = undefined;
															return setStore({ order });
														}}
													>
														<div style={{ fontWeight: 400, textShadow: '0px 0px 1px #555' }}>
															{de ? de.format('D') : ds.format('D')}
														</div>
														<small>{active ? de ? de.format('HH:mm') : ds.format('HH:mm') : <br />}</small>
														{active ? (
															<div
																style={{
																	position: 'relative',
																	overflow: 'hidden',
																	color: `#${ colorScheme.mainColorDark }`,
																}}
															>
																{start && ds.isAfter(start) ? (
																	price?.price ? (
																		<>{price?.price} €</>
																	) : (
																		<>
																			<Loader />
																			<br />
																		</>
																	)
																) : !start || ds.isAfter(start) ? (
																	<> {block?.price} €</>
																) : (
																	<br />
																)}
															</div>
														) : (
															<div>{(reserved && <small>Varattu</small>) || <br />}</div>
														)}
													</Dateblock>
												);
											})}
										</Timeblocks>
									),
								)}
							</>
						</Swipe>
					</>
				)}
			/>
		</>
	);
}
