import { useStated } from 'hooks/Hooks';
import { BackendURL } from 'utils/Api';

type Props = {
	src: string;
	width?: number;
	alt?: string;
	style?: React.CSSProperties;
};

export function Image(props: Props)
{
	const { src, width, style, alt } = props;
	const [state, set] = useStated({
		ready: !src?.length ? 'error' : (false as boolean | 'error'),
	});

	const { ready } = state;
	const image = `${ BackendURL }/thumb?u=${ src }${ width ? `&w=${ width }` : '' }`;

	return ready !== 'error' ? (
		<img
			style={style}
			src={image}
			onLoad={() => set({ ready: true })}
			onError={() => set({ ready: 'error' })}
			alt={alt || 'Kuva'}
		/>
	) : (
		<img
			style={style}
			src={'/noimage.png'}
			alt={alt || 'Kuva'}
		/>
	);
}
