import _ from 'lodash';
import { useState, useEffect } from 'react';

export function useStated<S extends object>(initial: S): [S, (value: Partial<S>) => void]
{
	const [state, set] = useState<S>(initial);
	const setter = (value: Partial<S>): void =>
	{
		const next = { ...state, ...value };
		// if (_.isEqual(state, next)) return;
		set(next);
		Object.assign(state, next);
	};

	return [state, setter];
}

type Unmount = void | (() => void);
type Destructor = Unmount | Promise<Unmount>;

export function useMount<D extends object | undefined>(callback: (prev?: D) => Destructor, deps?: D)
{
	const [state] = useState({
		prev: _.cloneDeep(deps) as D | undefined,
		renders: 0 as number,
	});

	if (state.renders === 0 || !_.isEqual(state.prev, deps))
	{
		state.renders++;
	}

	return useEffect((): Unmount =>
	{
		state.prev = _.cloneDeep(deps);
		const destructor = callback(state.prev);

		return (): void =>
		{
			if (typeof destructor === 'function') return destructor?.();
			if (typeof destructor === 'object' && 'then' in destructor)
			{
				destructor?.then?.(fn =>
				{
					if (typeof fn === 'function') fn?.();
				});
			}
		};
	}, [state.renders]) as Destructor;
}
