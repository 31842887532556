import { setStore, useStore } from 'store';
import {SelectBoxes} from 'components/SelectBoxes';
import { Redirect } from 'react-router-dom';
import { HeaderItem } from './Blocks/HeaderItem';
import { Picture } from 'components/Picture';

export function TabOffice()
{
	const order = useStore(s => s.order);
	const { company, office, resource, offices } = order;

	if (resource?.id) return <Redirect to={`/varaus`} />;
	if (office?.id) return <Redirect to={`/valinnat`} />;

	return (
		<>
			{
				<>
					<div className='text-center d-flex p-0'>
						<HeaderItem>
							<div style={{ height: '125px' }}>
								<Picture src={company?.picture} />
							</div>
							<div>
								<div>{company?.name}</div>
							</div>
						</HeaderItem>
					</div>

					<SelectBoxes
						value={office}
						options={offices?.data}
						info={(v: any) => v?.address + ', ' + v?.city}
						onChange={(v: any) =>
						{
							if (!v.id) return;

							setStore({
								order: {
									...order,
									office: v,
									resource: undefined,
									order_lines: [],
									start: undefined,
									end: undefined,
								}
							});
						}}
					/>
				</>
			}
		</>
	);
}
