import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';
import { Spinner } from './Spinner';

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 0.8; }
`;

const Container = styled.div`
	position: absolute;
	left: 0%;
	top: 0%;
	right: 100%;
	bottom: 100%;
	width: 100%;
	height: 100%;
	/* background: #fff7; */
	background: inherit;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	animation: forwards ${ fadeIn };
	animation-delay: 50ms;
	animation-duration: 250ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;

	svg {
		font-size: 1.3em;
	}
`;

type Props = {
	children?: ReactNode;
	size?: number;
};

export const Loader = (props: Props) =>
{
	const { children, size } = props;
	return (
		<Container>
			{
				children || <Spinner style={{ ...(size && { fontSize: `${ size }px` }) }} />
				// <i
				// 	className='fa fa-gear fa-spin'
				// 	style={{ ...(size && { fontSize: `${ size }px` }) }}
				// />
			}
		</Container>
	);
};
