import _ from 'lodash';
import styled, { css } from 'styled-components';
import { useStated } from 'hooks/Hooks';
import React from 'react';

type Props = {
	children: any;
	onMove: (x: number) => boolean;
	onEnd: (x: number) => boolean;
};

const SwipeFrame = styled.div<{ transitions?: boolean; }>`
	position: absolute;
	width: 100%;
	height: 100%;
	${ props =>
		!props.transitions &&
		css`
			* {
				transition: none !important;
			}
		`}
`;

export function Swipe(props: Props)
{
	const [state, set] = useStated({
		swipe: {
			sx: 0,
			sy: 0,
			ox: 0,
			oy: 0,
			scroll: 0,
			animate: false,
		},
	});

	const area = React.useRef<HTMLDivElement>(null);
	let oldContent: any = false;
	let scrollTimer: any = undefined;
	let contentTimer: any = undefined;

	const swipeMinWidth = 10;
	const swipeWidth = 100;
	const duration = 250;

	const swipeStart = (x: number, y: number) =>
	{
		const { swipe } = state;
		swipe.sx = x;
		swipe.sy = y;
		oldContent = React.cloneElement(props.children);

		set({ swipe });
	};

	const swipeMove = (x: number, y: number) =>
	{
		const { swipe } = state;
		const { onMove } = props;
		const bbox = area?.current?.getBoundingClientRect();

		swipe.ox = x - swipe.sx;
		swipe.oy = y - swipe.sy;

		if (swipe.ox > (bbox?.width || window.innerWidth)) swipe.ox = bbox?.width || window.innerWidth;
		if (swipe.ox < -(bbox?.width || window.innerWidth)) swipe.ox = -(bbox?.width || window.innerWidth);

		// if (Math.abs(swipe.ox) > 0)
		// {
		// 	const divider = 1 - Math.min(1, Math.abs(swipe.ox) / (bbox?.width || 0));
		// 	// swipe.ox = ((bbox?.width || 0) * divider);
		// 	// console.log(divider, swipe.ox, x - swipe.sx);
		// }

		if (Math.abs(swipe.ox / swipeMinWidth) < 1) swipe.ox /= Math.abs(swipeMinWidth / swipe.ox);
		if (Math.abs(swipe.oy) > Math.abs(swipe.ox)) swipe.ox = 0;
		if (!onMove(swipe.ox)) swipe.ox = 0;

		if (contentTimer) clearTimeout(contentTimer);

		if (!contentTimer && oldContent === false)
		{
			oldContent = React.cloneElement(props.children);
		}

		set({ swipe });
	};

	const swipeEnd = () =>
	{
		const { swipe } = state;
		const { onEnd } = props;
		const ox = swipe.ox / swipeWidth;
		const bbox = area?.current?.getBoundingClientRect();

		if (ox < -1)
		{
			if (onEnd(ox >= 1 ? 1 : ox <= -1 ? -1 : 0)) swipe.scroll = (bbox?.width || window.innerWidth) + swipe.ox;
			swipe.animate = true;
		} else if (ox > 1)
		{
			if (onEnd(ox >= 1 ? 1 : ox <= -1 ? -1 : 0)) swipe.scroll = swipe.ox - (bbox?.width || window.innerWidth);
			swipe.animate = true;
		} else onEnd(0);

		if (scrollTimer) clearTimeout(scrollTimer);
		if (contentTimer) clearTimeout(contentTimer);

		swipe.ox = 0;
		set({ swipe });

		scrollTimer = setTimeout(() =>
		{
			const { swipe } = state;
			swipe.scroll = 0;
			set({ swipe });
		}, 10);

		contentTimer = setTimeout(() =>
		{
			const { swipe } = state;
			oldContent = false;
			swipe.animate = false;
			set({ swipe });
		}, duration);
	};

	const { swipe } = state;
	const { children } = props;
	// const bbox = area?.current?.getBoundingClientRect();

	return (
		<div
			ref={area}
			className='row w-100 m-0'
			style={{ flex: 1, position: 'relative' }}
			onTouchStart={e =>
			{
				if (!e.touches?.[0]) return;
				swipeStart(e.touches[0].screenX, e.touches[0].screenY);
			}}
			onTouchMove={e =>
			{
				if (!e.touches?.[0]) return;
				swipeMove(e.touches[0].screenX, e.touches[0].screenY);
			}}
			onTouchEnd={() =>
			{
				swipeEnd();
			}}
			onMouseDown={e =>
			{
				swipeStart(e.screenX, e.screenY);
			}}
			onMouseMove={e =>
			{
				if (e.buttons === 1) swipeMove(e.screenX, e.screenY);
				// else swipeEnd();
			}}
			onMouseUp={() =>
			{
				swipeEnd();
			}}
		>
			<SwipeFrame
				transitions={!swipe.animate}
				style={{
					...(swipe.ox !== 0
						? {
							left: `calc(${ swipe.ox }px)`,
						}
						: {
							left: `calc(${ swipe.scroll }px - ${ Math.min(1, Math.max(swipe.ox, -1)) * 100 }%)`,
							...(swipe.scroll === 0 && { transition: `${ duration }ms all ease-in-out` }),
						}),
				}}
			>
				{swipe.ox !== 0 ? oldContent || children : children}
			</SwipeFrame>

			{Boolean(swipe.ox > 0 || swipe.animate) && (
				<SwipeFrame
					style={{
						...(swipe.ox !== 0
							? {
								// filter: `blur(${ Math.min(1, 1 - (Math.abs(swipe.ox) / swipeWidth)) * 10 }px)`,
								left: `calc(${ swipe.ox }px - 100%)`,
							}
							: {
								left: `calc(${ swipe.scroll }px - ${ Math.min(1, Math.max(swipe.ox, -1)) * 100 }% - 100%)`,
								...(swipe.scroll === 0 && { transition: `${ duration }ms all ease-in-out` }),
							}),
					}}
				>
					{swipe.ox !== 0 ? children : oldContent}
				</SwipeFrame>
			)}

			{Boolean(swipe.ox < 0 || swipe.animate) && (
				<SwipeFrame
					style={{
						...(swipe.ox !== 0
							? {
								// filter: `blur(${ Math.min(1, 1 - (Math.abs(swipe.ox) / swipeWidth)) * 10 }px)`,
								left: `calc(${ swipe.ox }px + 100%)`,
							}
							: {
								left: `calc(${ swipe.scroll }px - ${ Math.min(1, Math.max(swipe.ox, -1)) * 100 }% + 100%)`,
								...(swipe.scroll === 0 && { transition: `${ duration }ms all ease-in-out` }),
							}),
					}}
				>
					{swipe.ox !== 0 ? children : oldContent}
				</SwipeFrame>
			)}
		</div>
	);
}
