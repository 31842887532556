import hash_sum from 'hash-sum';
import { useMount, useStated } from 'hooks/Hooks';
import { setStore, useStore } from 'store';
import { SqlDate } from 'utils/Dates';
import { Post } from 'utils/Api';

export function usePrice()
{
	const order = useStore(s => s.order);
	const { resource, start, end, order_lines } = order;
	const [cache, setCache] = useStated({} as Record<string, any>);

	return useMount(async () =>
	{
		order.calcprice = undefined;
		setStore({ order });

		if (!resource || !start || !end) return;

		const cacheTime = Math.floor(Date.now() / 1000 / 60);
		const cacheKey = hash_sum({ cacheTime, resource, start, end, order_lines });
		if (cache[cacheKey]) return (order.calcprice = cache[cacheKey]);

		const controller = new AbortController();
		order.calcprice = await Post({
			path: 'get_price_v2',
			body: {
				resource: String(resource?.id || resource || ''),
				start: SqlDate(start),
				ending: SqlDate(end),
				order_lines,
			},
			extra: { signal: controller.signal },
		});

		cache[cacheKey] = order.calcprice;
		setCache(cache);
		setStore({ order });
		return () => controller?.abort?.();
	}, [resource, start, end, order_lines]);
}
