import _ from 'lodash';
import styled from 'styled-components';
import { ReactNode, useCallback } from 'react';
import { useStated } from 'hooks/Hooks';
import { BackendURL } from 'utils/Api';

export type AddressResult = {
	text: string;
	streetAddress: string;
	postalCode: string;
	addressLocality: string;
};

const SearchDropdown = styled.div`
	position: absolute;
	top: 99%;
	background: #fff;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0px 0px 8px 0px #00000022;
	z-index: 1;

	> div {
		padding: 8px 10px;
		cursor: pointer;
	}

	> div:hover {
		background: #00000010;
	}
`;

type Props<T> = {
	value: string;
	placeholder: string;
	htmlName: string;
	onChange: (value: string) => void;
	onSelect: (data: T) => void;
	renderItem: (data: T) => ReactNode;
};

export function SearchList<T extends object>(props: Props<T>)
{
	const [state, set] = useStated({ result: [] as T[] });

	const { value, placeholder, htmlName, onChange, onSelect, renderItem } = props;
	const { result } = state;

	const onSearch = useCallback(
		_.debounce(async (s: string): Promise<void> =>
		{
			if (s.length < 3) return;
			const req = await fetch(`${ BackendURL }/map_search?search=${ s }`);
			const json = await req?.json?.();
			return set({ result: json?.data });
		}, 100),
		[],
	);

	return (
		<div style={{ position: 'relative' }}>
			<input
				required
				spellCheck={false}
				autoFocus={false}
				name={htmlName}
				autoComplete={htmlName}
				className='form-control mb-1'
				style={{ fontSize: '0.85rem' }}
				value={value}
				placeholder={placeholder}
				onChange={e =>
				{
					const value = e.target.value;
					onChange(value);
					onSearch(value);
				}}
				onBlur={async () =>
				{
					await new Promise(r => setTimeout(r, 100));
					set({ result: [] });
				}}
			/>
			{result.length > 0 && (
				<SearchDropdown>
					{_.map(result, (v, k) =>
					{
						return (
							<div
								key={k}
								onMouseDown={() => onSelect(v)}
								onTouchStart={() => onSelect(v)}
							>
								{renderItem(v)}
							</div>
						);
					})}
				</SearchDropdown>
			)}
		</div>
	);
}
