import { setStore, useStore } from 'store';
import { Redirect } from 'react-router-dom';
import { DEVELOPMENT } from 'utils/Api';
import { useStated } from 'hooks/Hooks';
import { BottomBox } from 'components/ConfirmBox';
import { Input } from './../Input';
import { AddressResult, SearchList } from './Blocks/SearchList';
import { TabParent } from './Blocks/TabParent';

export function TabCustomer()
{
	const [state, set] = useStated({
		submit: false,
	});

	const [order, appStatus] = useStore(s => [s.order, s.appStatus]);
	const { start, end, company, office, resource, customer } = order;
	const terms = String(resource?.terms || office?.terms || company?.terms || '');
	const { agreed } = appStatus || {};
	const { submit } = state;

	if (!office || !resource || !start || !end) return <Redirect to={'/'} />;
	if (submit) return <Redirect to={'/vahvistus'} />;

	return (
		<form
			style={{ display: 'contents' }}
			onSubmit={e =>
			{
				e.preventDefault();
				set({ submit: true });
				setStore({ appStatus: { ...appStatus, formValid: true } });
				return false;
			}}
		>
			<TabParent>
				<div className='row p-2 m-0'>
					<div className='col-12 mt-2'>
						<p className='mt-2 mb-3'>
							<b style={{ fontSize: '1.25em' }}>Yhteystiedot varaukselle</b>
						</p>
						<div className='mt-3'>
							<label className='row'>
								<div
									className='col-4 col-sm-3 pr-0'
									style={{ alignSelf: 'center' }}
								>
									Yritysasiakas
								</div>
								<div className='col-8 col-sm-9'>
									<label
										className='switch m-0'
										style={{ alignSelf: 'center' }}
									>
										<input
											type='checkbox'
											autoFocus={false}
											checked={customer?.iscompany}
											onChange={e =>
											{
												order.customer = { ...order.customer, iscompany: e.target.checked };
												setStore({ order });
											}}
										/>
										<span className='switchslider round' />
									</label>
								</div>
							</label>

							<Input
								field='name'
								htmlName='name'
								label={customer?.iscompany ? 'Yrityksen nimi' : 'Etu- ja sukunimi'}
							// {...!customer?.iscompany && {
							// 	pattern: '^.+?\\s+?.+?$',
							// 	title: 'Etu- ja sukunimi',
							// }}
							/>

							<div style={{ position: 'relative' }}>
								<label className='row'>
									<div
										className='col-4 col-sm-3 pr-0'
										style={{ alignSelf: 'center' }}
									>
										Osoite
									</div>
									<div className='col-8 col-sm-9'>
										<SearchList<AddressResult>
											htmlName='street-address'
											placeholder='Katuosoite'
											value={order.customer?.address}
											renderItem={v => v?.text}
											onChange={value =>
											{
												order.customer = { ...order.customer, address: value };
												setStore({ order });
											}}
											onSelect={data =>
											{
												order.customer = {
													...order.customer,
													address: data?.streetAddress,
													postal: data?.postalCode,
													city: data?.addressLocality,
												};
												setStore({ order });
											}}
										/>
									</div>
								</label>
							</div>

							<Input
								field='postal'
								label={'Postinumero'}
								htmlName='postal-code'
							// pattern='\d+' title='Postinumero'
							/>
							<Input
								field='city'
								label={'Toimipaikka'}
								htmlName='address-level2'
							/>
							<Input
								type='tel'
								field='phone'
								label={'Puhelin'}
								htmlName='tel'
							/>
							<Input
								type='email'
								field='email'
								label={'Sähköposti'}
								htmlName='email'
							/>
							{customer?.iscompany && (
								<Input
									label={'Y-tunnus'}
									field='vatnumber'
									htmlName='vat-number'
								/>
							)}

							<hr />

							<label className='row'>
								<div
									className='col-9 col-sm-3 pr-0'
									style={{ alignSelf: 'center' }}
								>
									<>Hyväksyn </>
									{terms && (
										<>
											<a
												target='_blank'
												rel='noreferrer'
												href={terms}
											>
												{' '}
												käyttöehdot{' '}
											</a>
											<> ja </>
										</>
									)}
									<a
										target='_blank'
										rel='noreferrer'
										href='https://oitis.fi/tietosuojaseloste.html'
									>
										{' '}
										tietosuojaselosteen{' '}
									</a>
								</div>
								<div className='col-3 col-sm-9'>
									<label
										className='switch m-0'
										style={{ alignSelf: 'center' }}
									>
										<input
											type='checkbox'
											checked={agreed}
											autoFocus={false}
											onChange={e =>
											{
												setStore({ appStatus: { ...appStatus, agreed: e.target.checked } });
											}}
										/>
										<span className='switchslider round' />
									</label>
								</div>
							</label>

							{DEVELOPMENT && (
								<div className='pt-2 pb-3'>
									<button
										type='button'
										className='btn btn-sm btn-outline-secondary'
										onClick={() =>
										{
											setStore({
												order: {
													...order,
													customer: {
														name: 'Teppo Testaaja',
														address: 'Aurakatu 1',
														postal: '20100',
														city: 'Turku',
														phone: '0401231234',
														email: 'info@oitis.fi',
													},
												},
												appStatus: { ...appStatus, formValid: true },
											});
										}}
									>
										DEVAUS: TÄYTÄ TESTIDATALLA
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</TabParent>
			<BottomBox active={true}>
				<button
					type='submit'
					className='btn btn-success'
					style={{ minWidth: '250px' }}
					disabled={!appStatus?.agreed}
				>
					Siirry vahvistamaan varaus
				</button>
			</BottomBox>
		</form>
	);
}
