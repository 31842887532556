import styled from 'styled-components';

export const TabParent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden auto;
	max-width: 1140px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	background: #fefefe;
	position: relative;

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	::-webkit-scrollbar {
		/* WebKit */
		width: 0;
	}
`;
