import styled from 'styled-components';
import moment from 'moment';
import { ReactNode } from 'react';
import { setStore, useStore } from 'store';
import { Post } from '../../utils/Api';

import { CalendarContainer } from './Blocks/CalendarContainer';
import { Button } from 'components/Button';
import { TimesContainer } from './Blocks/TimesContainer';
import { ConfirmBox } from 'components/ConfirmBox';
import { TabParent } from './Blocks/TabParent';
import { Redirect } from 'react-router-dom';
import { SqlDate } from 'utils/Dates';
import { useMount, useStated } from 'hooks/Hooks';
import { HeaderItem } from './Blocks/HeaderItem';
import { Loader } from 'components/Loader';
import { Picture } from 'components/Picture';
import { SlotsContainer } from './Blocks/SlotsContainer';

const Center = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export function TabSchedule()
{
	const [state, set] = useStated({
		timeType: undefined as 'time' | 'calendar' | 'slot' | void,
		error: undefined as ReactNode | void,
	});

	const order = useStore(s => s.order);
	const { office, resource, company } = order;
	const { timeType, error } = state;

	useMount(async () =>
	{
		if (!resource) return;
		console.log('Get price blocks and schedules');

		order.schedules = await Post({
			path: 'schedule',
			body: {
				ending: '>=' + SqlDate(moment().subtract(1, 'days')),
				start: '<' + SqlDate(moment().add(30, 'days')),
			},
			header: { limit: 1000 },
		});
		setStore({ order });

		try
		{
			const prices = await Post<any>({
				path: 'get_price_blocks',
				body: {
					start: SqlDate(moment().startOf('day')),
					ending: SqlDate(moment().endOf('day')),
					resource: String(resource?.id || resource || ''),
					accumulate: false,
				},
			});

			if (resource?.reservation_mode === 'slot') return set({ timeType: 'slot' });
			if (resource?.reservation_mode === 'time') return set({ timeType: 'time' });
			if (resource?.reservation_mode === 'day') return set({ timeType: 'calendar' });
			if (prices?.length <= 1) return set({ timeType: 'calendar' });
			return set({ timeType: 'time' });
		}
		catch (e)
		{
			console.error('Error:', e);
			set({
				error: <>Hinnoittelu puuttuu, yritä myöhemmin uudelleen</>,
			});
		}
	});

	if (!office?.id) return <Redirect to={`/`} />;
	if (!resource?.id) return <Redirect to={`/valinnat`} />;

	return (
		<>
			<TabParent>
				<div className='text-center d-flex p-0'>
					<HeaderItem>
						<div style={{ height: '125px' }}>
							<Picture src={resource?.picture || office?.picture || company?.picture} />
						</div>
						<div>
							<div>{resource?.name}</div>
							<div>
								<Button
									onClick={() =>
									{
										setStore({
											order: {
												...order,
												resource: undefined,
												order_lines: [],
											},
										});
									}}
								>
									Vaihda
								</Button>
							</div>
						</div>
					</HeaderItem>
				</div>
				{(timeType === 'calendar' && <CalendarContainer />) ||
					(timeType === 'time' && <TimesContainer />) ||
					(timeType === 'slot' && <SlotsContainer />) ||
					(error && <Center>{error}</Center>) || <Loader size={42} />}
			</TabParent>

			<ConfirmBox />
		</>
	);
}