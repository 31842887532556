// import { Post } from './../utils/Post';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { getDuration } from 'App';
import { useStore } from 'store';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { ToCurrency } from 'utils/Dates';
import { usePrice } from './UsePrice';
import { Loader } from './Loader';

export const BottomBox = styled.div<{ active?: boolean; }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: #f2f2f2;
	box-shadow: 0 0 5px #999;
	z-index: 9999;
	overflow: hidden;
	min-height: 0px;
	height: 0px;
	width: 100%;
	padding: 0px;
	text-align: center;
	transition: all 150ms ease-in-out;

	${ p =>
		p?.active &&
		css`
			max-height: 80px;
			min-height: 80px;
			height: 80px;
			padding: 5px;
		`}
`;

export function ConfirmBox()
{
	usePrice();
	const order = useStore(s => s.order);
	const { start, end, office, resource, calcprice } = order;

	return (
		<BottomBox active={Boolean(office && resource && start)}>
			{(start && end && (
				<>
					<div>
						<NavLink to='/yhteystiedot'>
							<button
								className='btn btn-success'
								style={{ position: 'relative', overflow: 'hidden', minWidth: '250px' }}
							>
								<b>Tee varaus</b>
								<i
									className='fa fa-clock-o mr-1 ml-2'
									style={{ fontSize: '12px' }}
								/>
								{getDuration(start, end)}
								<i
									className='fa fa-tag mr-1 ml-2'
									style={{ fontSize: '12px' }}
								/>
								{calcprice?.price ? ToCurrency(calcprice.price) : <Loader />}
							</button>
						</NavLink>
					</div>
					<div className='mt-1'>
						{Boolean(start && end) && (
							<>
								{/* <div>Valittu:  {getDuration(start, end)}</div> */}
								<div>
									{_.upperFirst(moment(start).format('ddd D.M'))} klo {moment(start).format('HH:mm')}
									<i
										className='fa fa-arrow-right mr-2 ml-2'
										style={{ fontSize: '12px' }}
									/>
									{_.upperFirst(moment(end).format('ddd D.M'))} klo {moment(end).format('HH:mm')}
								</div>
							</>
						)}
					</div>
				</>
			)) || (
					<>
						<div
							className='m-0'
							style={{ fontSize: '14px' }}
						>
							<i
								className='fa fa-info-circle mr-2'
								style={{ fontSize: '20px', color: '' }}
							/>
							<span>Valitse seuraavaksi päättymisaika</span>
						</div>
					</>
				)}
		</BottomBox>
	);
}
