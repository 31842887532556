import './errors';

// import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { App } from './App';

import moment from 'moment';
import 'moment/locale/fi';
moment.locale('fi');

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

// const elem = document.getElementById('root') as HTMLElement;
// const root = createRoot(elem);
// root.render(<App />);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
