import { useStated } from 'hooks/Hooks';
import styled from 'styled-components';

const ButtonElement = styled.button``;

type Props = {
	children: React.ReactNode;
	onClick?: React.MouseEventHandler;
};

export function Button(props: Props)
{
	const [state, set] = useStated({
		active: false as boolean,
	});

	const { children, onClick } = props;
	const { active } = state;

	return (
		<ButtonElement
			className={'btn btn-success' + (active ? ' active' : '')}
			onClick={onClick}
			onTouchStart={() =>
			{
				set({ active: true });
			}}
			onTouchMove={() =>
			{
				set({ active: false });
			}}
		>
			{children}
		</ButtonElement>
	);
}
