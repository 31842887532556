import { createStore } from 'hooks/BaseStore';
import { Entry, PostResponse } from 'utils/Api';

const order = {
	start: undefined as void | Date,
	end: undefined as void | Date,
	calcprice: undefined as void | { price: number; total: number; order_lines: any; },
	order_lines: [] as { resource: string; amount: number; }[],
	infotext: '',

	customer: {} as Record<string, any>,
	resource: undefined as void | Entry,
	office: undefined as void | Entry,
	company: undefined as void | Entry,

	offices: undefined as void | PostResponse,
	resources: undefined as void | PostResponse,
	schedules: undefined as void | PostResponse,
};

const appStatus = {
	formValid: false,
	agreed: false,
	payment_schedule_id: '',
};

const state = {
	order,
	appStatus,
};

export const { useStore, getStore, setStore } = createStore(state);